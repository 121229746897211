/************ GENRAL SETTINGS ************/
:root {
  --color1: #e63719;
  --color2: #e5391c;
  --color3: #233765;
  --color4: #ff4500;
  --color5: #c04000;
  --color1-transparent-15: rgb(251, 206, 177);
}
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}

.btn-primary {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}

.btn-primary:hover {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}

.btn-danger {
  color: #fff;
  background-color: var(--color1);
  font-size: 18px;
  border-color: var(--color1);
  background: var(--color1);
}

.btn-danger:hover {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: var(--color1);
}

.btn-secondary {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}

a {
  color: var(--color1);
  text-decoration: none;
}
a:hover {
  color: var(--color1);
}

.spinner-loading {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-loading.spinner-border {
  width: 15rem !important;
  height: 15rem !important;
}

.spinner-loading.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor-hand {
  cursor: pointer;
}

.counter-span {
  background: var(--color5);
}
/****SEARCH CUSTOMER****/
.search-customer {
  position: relative;
}

.search-customer ul {
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid white;
  border-radius: 0.3rem;
  z-index: 5;
  margin: 0;
  padding: 1rem;
  list-style-type: none;
  max-height: 20rem;
  overflow-y: scroll;
}

.search-customer ul li {
  padding: 0.4rem 0;
  border-bottom: 1px solid var(--color1-transparent-15);
  cursor: pointer;
}

.search-customer ul li:hover {
  background-color: var(--color1-transparent-15);
}

.search-customer ul li:last-child {
  border-bottom: none;
}

/************ SECTION TITLE ************/

.section-title h2 {
  font-weight: 400;
  color: var(--color2);
}

.section-title span {
  display: inline-block;
  width: 5rem;
  height: 0.5rem;
  background-color: var(--color2);
}

.topbar {
  background-color: var(--color2);
  padding: 0.5rem;
}

.topbar ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
}

.topbar ul li {
  padding-left: 1rem;
}

/************ MENUBAR ************/
.menubar .navbar-nav {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 450px) {
  .menubar .navbar-nav {
    flex-direction: column;
  }
}

.menubar .navbar-nav a.active {
  border-bottom: 0.3rem solid var(--color2);
}

/************ PAGE HEADER ************/

.page-header {
  padding: 4rem;
  text-align: center;
  position: relative;
}

.page-header::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.3;
  z-index: 1;
}
.client-carousel.owl-theme .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
}
.client-carousel.owl-theme .owl-nav button {
  padding: 0;
  margin: 0;
  margin-right: 45px;
  position: relative;
  line-height: 1;
  background-color: #fff;
}
.client-carousel.owl-theme .owl-nav button:first-child {
  margin-right: 0;
}
.client-carousel.owl-theme .owl-nav button:hover {
  background: 0 0;
}
.client-carousel.owl-theme .owl-nav button:hover span:before {
  color: #f11a10;
}
.client-carousel.owl-theme .owl-nav button span:before {
  margin-left: 0;
  font-size: 40px;
  color: #2b71f3;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.client-carousel.owl-theme .owl-nav button:before {
  content: "";
  border-width: 1px;
  border-color: #b6b6b6;
  border-style: solid;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.client-carousel.owl-theme .owl-nav button:first-child:before {
  left: auto;
  right: 45%;
  -webkit-animation: carousel-left 3s infinite alternate linear;
  animation: carousel-left 3s infinite alternate linear;
}
.client-carousel.owl-theme .owl-nav button:last-child:before {
  left: 45%;
  -webkit-animation: carousel-right 3s infinite alternate linear;
  animation: carousel-right 3s infinite alternate linear;
}
.search-any {
  background-color: #f1f1f1;
  color: rgb(0, 0, 0);
}
.search-any {
  border-radius: 20px;
}
.search-any h3 span {
  display: inline-block;
  border-bottom: 3px rgb(0, 0, 0) solid;
}
.pagination {
  float: right;
  margin: 0 0 5px;
}
.pagination li a {
  border: none;
  font-size: 13px;
  min-width: 30px;
  min-height: 30px;
  color: #999;
  margin: 0 2px;
  line-height: 30px;
  border-radius: 2px !important;
  text-align: center;
  padding: 0 6px;
}
.pagination li a:hover {
  color: #666;
}
.pagination li.active a,
.pagination li.active a.page-link {
  background: #03a9f4;
}
.pagination li.active a:hover {
  background: #0397d6;
}
.pagination li.disabled i {
  color: #ccc;
}
.pagination li i {
  font-size: 16px;
  padding-top: 6px;
}
.hint-text {
  float: left;
  margin-top: 10px;
  font-size: 13px;
}

.page-header::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color1-transparent-15);
  z-index: 2;
}

.page-header .header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.page-header .breadcrumb a {
  background-color: white;
  text-decoration: none;
}

/************ ADMIN ************/

.image-area {
  background-color: var(--color1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-area button {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 0.8;
}

.image-area-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.footer-bg {
  background: var(--color3);
  position: relative;
  padding-top: 100px;
  padding-bottom: 30px;
}

/************ FOOTER ************/
footer {
  background-color: var(--color4);
  padding: 0rem;
}

.footer-list li {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 12px;
  text-align: left;
}

.footer-content-title h3 {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  text-align: left;
}
ol,
ul {
  padding-left: 0rem;
}
.ajs-header {
  background-color: var(--color1) !important;
  color: white !important;
}

.ajs-close {
  color: white;
}
.ajs-ok {
  background-color: var(--color1) !important;
  color: white !important;
}
.counter-item h3 {
  -webkit-text-fill-color: var(--color3);
}
.counter-item p {
  color: var(--color3);
}
.btn1.blue-gradient {
  background: var(--color1);
}
.blue-gradient {
  background: var(--color1);
  color: #fff;
}
.home-service-start h2 {
  color: var(--color2);
}
.home-service-text h3,
.home-service-text-2 h3 {
  color: var(--color2);
}
.pricing-item-amount h4 {
  color: var(--color2);
}
.pricing-item-amount {
  background-color: rgba(255, 144, 41, 0.2);
}
.pricing-item-amount:before {
  background: var(--color1);
}

.alertify .ajs-commands button.ajs-close {
  background-color: white;
}

.section-button li .btn1 {
  font-weight: 400;
  color: var(--color1);
}

.availability-blue {
  background: var(--color1);
  color: #fff;
}

.availability-green {
  background: var(--color1);
  color: #fff;
}

.pricing-item-header h3 {
  color: var(--color3);
}

.blue-gradient:hover {
  background: var(--color1);
  border-color: white;
  color: #fff;
}

.btn-check:hover + .btn-primary:hover {
  background-color: var(--color1);
  border-color: var(--color1);
  border: none;
}

.btn-primary.dropdown-toggle:hover {
  background-color: var(--color1);
  border-color: var(--color1);
}

.btn-secondary :hover {
  color: #fff;
  border-color: var(--color1);
  background-color: var(--color1);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: var(--color1);
  background-color: transparent;
}
.bg-danger {
  background-color: var(--color1) !important;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}
.btn-outline-secondary {
  color: var(--color1);
  border-color: var(--color1);
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: var(--color1);
  border-color: var(--color1);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: --color1;
  background-color: transparent;
}

button.btn.btn-secondary {
  background-color: var(--color1);
  border-color: var(--color1);
}
button.btn.btn-danger {
  background-color: var(--color1);
  border-color: var(--color1);
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.2rem;
  border-radius: 2.5rem;
  font-size: 0.9rem;
}
.btn {
  padding: 0.375rem 0.75rem;
}

.field__input {
  --uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);

  background-color: transparent;
  border-radius: 0;
  border: none;

  -webkit-appearance: none;
  -moz-appearance: none;

  font-family: inherit;
  font-size: inherit;
}

.field__input:focus::-webkit-input-placeholder {
  color: var(--uiFieldPlaceholderColor);
}

.field__input:focus::-moz-placeholder {
  color: var(--uiFieldPlaceholderColor);
}

/*
=====
CORE STYLES
=====
*/

.field {
  --uiFieldBorderWidth: var(--fieldBorderWidth, 2px);
  --uiFieldPaddingRight: var(--fieldPaddingRight, 1rem);
  --uiFieldPaddingLeft: var(--fieldPaddingLeft, 1rem);
  --uiFieldBorderColorActive: var(
    --fieldBorderColorActive,
    rgba(22, 22, 22, 1)
  );

  display: var(--fieldDisplay, inline-flex);
  position: relative;
  font-size: var(--fieldFontSize, 1rem);
}

.field__input {
  box-sizing: border-box;
  width: var(--fieldWidth, 100%);
  height: var(--fieldHeight, 3rem);
  padding: var(--fieldPaddingTop, 1.25rem) var(--uiFieldPaddingRight)
    var(--fieldPaddingBottom, 0.5rem) var(--uiFieldPaddingLeft);
  border-bottom: var(--uiFieldBorderWidth) solid
    var(--fieldBorderColor, rgba(0, 0, 0, 0.25));
}

.field__input:focus {
  outline: none;
}

.field__input::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.field__input::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.field__input:focus::-webkit-input-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.field__input:focus::-moz-placeholder {
  opacity: 1;
  transition-delay: 0.2s;
}

.field__label-wrap {
  box-sizing: border-box;
  pointer-events: none;
  cursor: text;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.field__label-wrap::after {
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 0;
  opacity: 0;

  position: absolute;
  bottom: 0;
  left: 0;
}

.field__input:focus ~ .field__label-wrap::after {
  opacity: 1;
}

.field__label {
  position: absolute;
  left: var(--uiFieldPaddingLeft);
  top: calc(50% - 0.5em);

  line-height: 1;
  font-size: var(--fieldHintFontSize, inherit);

  transition: top 0.2s cubic-bezier(0.9, -0.15, 0.1, 1.15),
    opacity 0.2s ease-out, font-size 0.2s ease-out;
  will-change: bottom, opacity, font-size;
}

.field__input:focus ~ .field__label-wrap .field__label,
.field__input:not(:placeholder-shown) ~ .field__label-wrap .field__label {
  --fieldHintFontSize: var(--fieldHintFontSizeFocused, 0.75rem);

  top: var(--fieldHintTopHover, 0.25rem);
}

/* 
effect 1
*/

.field_v1 .field__label-wrap::after {
  border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  transition: opacity 0.2s ease-out;
  will-change: opacity;
}

/* 
effect 2
*/

.field_v2 .field__label-wrap {
  overflow: hidden;
}

.field_v2 .field__label-wrap::after {
  border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  transform: translate3d(-105%, 0, 0);
  will-change: transform, opacity;
  transition: transform 0.285s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
}

.field_v2 .field__input:focus ~ .field__label-wrap::after {
  transform: translate3d(0, 0, 0);
  transition-delay: 0;
}

/*
effect 3
*/

.field_v3 .field__label-wrap::after {
  border: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  will-change: opacity, height;
  transition: height 0.2s ease-out, opacity 0.2s ease-out;
}

.field_v3 .field__input:focus ~ .field__label-wrap::after {
  height: 100%;
}

/*
=====
LEVEL 4. SETTINGS
=====
*/

.field {
  --fieldBorderColor: #d1c4e9;
  --fieldBorderColorActive: #673ab7;
}

/*
=====
DEMO
=====
*/

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Open Sans,
    Ubuntu, Fira Sans, Helvetica Neue, sans-serif;
  margin: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  margin: auto;
  padding: 1rem;

  display: grid;
  grid-gap: 30px;
}

.linktr {
  order: -1;
  padding: 1.75rem;
  text-align: center;
}

.linktr__goal {
  background-color: rgb(209, 246, 255);
  color: rgb(8, 49, 112);
  box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
  border-radius: 2rem;
  padding: 0.5rem 1.25rem;
}

@media (min-width: 1024px) {
  .linktr {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}

.r-link {
  --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
  --uirLinkTextColor: var(--rLinkTextColor);
  --uirLinkTextDecoration: var(--rLinkTextDecoration, none);

  display: var(--uirLinkDisplay) !important;
  color: var(--uirLinkTextColor) !important;
  text-decoration: var(--uirLinkTextDecoration) !important;
}
.input-group-text {
  font-size: 1.4rem;
}
