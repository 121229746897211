.navbar {
  background-color: #fff;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 2;
}

.menu-bars {
  margin: 0 2rem;
  font-size: 2rem;
  background: none;
  color: #e63719;
}

.menu-bars svg:hover {
  color: rgb(11, 33, 84) !important;
}

.nav-menu {
  background-color: rgba(230, 55, 25, 0.85);
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 80px;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  top: 80px;
  left: 0;

  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0px 6px 16px;
  list-style: none;
  /* height: 55px; */
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: rgb(11, 33, 84);

  color: #fff;
}

.nav-menu-items {
  width: 250px;
  padding: 6px;
}

span {
  margin-left: 10px;
}

.logonavbar img {
  width: 180px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .logonavbar img {
    width: 140px;
    margin-top: 5px;
  }
  .menu-bars {
    margin: 0 1rem;
    font-size: 1.5rem;
    background: none;
    color: #e63719;
  }
  .navbar-option {
  }
}
.divider {
  width: 100%;
  height: 2px;
  background-color: #fff;
}
